<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <div class="task-container pa-9 pa-md-6 pa-lg-9">
      <v-container>
        <v-row align="center">
          <v-col cols="12" class="mb-4">
            <h3 class="page-title font-weight-bold">お知らせの編集</h3>
            <span
              >対象の従業員にお知らせを表示します。</span
            >
          </v-col>
          <v-col cols="12" v-if="post">
            <div class="form-content">
              <v-row align="center">
                <v-col cols="2" class="form-header text-heading-3 text-right"
                  >お知らせの日付</v-col
                >
                <v-col cols="4">
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="post.post_date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        hide-details
                        outlined
                        placeholder="日付を選択してください"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="post.post_date"
                      @input="menu = false"
                      :day-format="formatDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col cols="2" class="form-header text-heading-3 text-right"
                  >タイトル</v-col
                >
                <v-col cols="9" class="flex-grow-1">
                  <v-text-field
                    v-model="post.title"
                    class="form-text"
                    dense
                    hide-details
                    outlined
                    placeholder="お知らせのタイトルを入力してください。"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="2"
                  class="form-header text-heading-3 text-right mt-1"
                  >目標メンバー</v-col
                >
                <v-col class="flex-grow-1" cols="9">
                  <v-row align="center">
                    <v-col
                      cols="auto"
                      class="pb-0"
                      v-for="(participantCategory,
                      pCIndex) in participantCategories"
                      :key="`participant-${pCIndex}`"
                    >
                      <v-checkbox
                        class="mt-0"
                        :value="participantCategory.id"
                        :label="participantCategory.name"
                        v-model="selectedCategory"
                        @click="participantCategory.function"
                      >
                      </v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col cols="12">
                      <div class="user-type-label text-heading-3 pl-0">
                        一般社員
                      </div>
                      <div class="user-type-label-divider"></div>
                    </v-col>
                    <v-col
                      cols="auto"
                      class="py-0"
                      v-for="(user, userIndex) in userByType"
                      :key="`general-user-${userIndex}`"
                    >
                      <v-checkbox
                        class="my-0"
                        :value="user.id"
                        :label="user.full_name"
                        v-model="selectedGeneral"
                        style="display:inline-flex"
                      >
                      </v-checkbox>
                      <v-icon
                        size="14"
                        :color="getColor(user.id)"
                        class="mt-n6"
                        v-if="
                          selectedGeneral.includes(user.id) &&
                            generalUsers.includes(user.id)
                        "
                        >$checkCircleIcon</v-icon
                      >
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col cols="12">
                      <div class="user-type-label text-heading-3 pl-0">
                        日常清掃員
                      </div>
                      <div class="user-type-label-divider"></div>
                    </v-col>
                    <v-col
                      cols="auto"
                      class="pt-0"
                      v-for="(user, userIndex) in dailyCleaningUser"
                      :key="`general-user-${userIndex}`"
                      style="display:inline-flex"
                    >
                      <v-checkbox
                        class="my-0"
                        :value="user.id"
                        :label="user.full_name"
                        v-model="selectedCleaning"
                      >
                      </v-checkbox>
                      <v-icon
                        size="14"
                        :color="getColor(user.id)"
                        v-if="
                          selectedCleaning.includes(user.id) &&
                            cleaningUsers.includes(user.id)
                        "
                        >$checkCircleIcon</v-icon
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2" class="form-header text-heading-3 text-right"
                  >内容</v-col
                >
                <v-col cols="9" class="flex-grow-1">
                  <v-textarea
                    v-model="post.content"
                    class="form-text"
                    hide-details
                    outlined
                    placeholder="お知らせ内容"
                    rows="16"
                  ></v-textarea>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="create-task-footer px-6">
      <v-row>
        <v-col cols="auto" class="flex-grow-1">
          <v-btn rounded width="150" color="#AD4545" text @click="deleteItem()">
            <v-icon left>$trash</v-icon> 削除する
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            class="mr-10"
            rounded
            width="150"
            @click="$router.push('/post/list')"
            text
          >
            キャンセル
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            @click="save"
            color="#4F55A7"
            class="white--text"
            width="150"
            :loading="loading"
          >
            更新する
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import dayjs from '@/plugins/dayjs'
import { mapMutations } from 'vuex'
export default {
  name: 'Edit',
  props: ['id'],
  data() {
    return {
      modal: {
        birth_day: false,
        date_of_joining: false,
        delete: false
      },
      menu: false,

      selectedCategory: [],
      participantCategories: [
        {
          id: 1,
          name: '全員参加',
          function: this.toggleAll
        },
        {
          id: 2,
          name: '一般全員',
          function: this.toggleGeneral
        },
        {
          id: 3,
          name: '日常清掃全員',
          function: this.toggleCleaning
        }
      ],
      selectedGeneral: [],
      selectedCleaning: [],
      generalUsers: [],
      cleaningUsers: [],
      // statuses: [
      //   { value: 1, text: '表示' },
      //   { value: 0, text: '非表示' }
      // ],

      titleMaxWord: 30,
      descriptionMaxWord: 300,
      rules: {
        title: [
          v => !!v || 'タイトルが必要です。',
          v =>
            (v && v.length <= this.titleMaxWord) ||
            `タイトルは${this.titleMaxWord}文字未満である必要があります`
        ],
        content: [
          v => !!v || '説明が必要です',
          v =>
            (v && v.length <= this.descriptionMaxWord) ||
            `説明は${this.descriptionMaxWord}文字未満である必要があります`
        ]
      },
      loading: false,
      errorMessages: '',
      overlay: false
    }
  },
  computed: {
    post() {
      return this.$store.getters.post
    },
    userByType() {
      return this.$store.getters.generalUser
    },
    dailyCleaningUser() {
      return this.$store.getters.dailyCleaningUser
    },
    selectedUsers() {
      return [...this.selectedGeneral, ...this.selectedCleaning]
    }
  },
  created() {
    this.overlay = true
    this.$store
      .dispatch('POST_GET', this.id)
      .then(() => {
        this.loading = false
        this.loadUSers()
      })
      .finally(() => {
        this.overlay = false
      })
    this.getGeneralUser('通常業務')
    this.getDailyCleaning('日常清掃員')
  },

  methods: {
    ...mapMutations(['showModal']),
    formatDate(e) {
      return dayjs(e).format('D')
    },
    loadUSers() {
      var generalUserIDs = this.userByType.map(a => a.id)
      var CleaningUserIDs = this.dailyCleaningUser.map(a => a.id)
      this.post.users.forEach(element => {
        if (generalUserIDs.includes(element.id)) {
          this.selectedGeneral.push(element.id)
          this.generalUsers.push(element.id)
        }
        if (CleaningUserIDs.includes(element.id)) {
          this.selectedCleaning.push(element.id)
          this.cleaningUsers.push(element.id)
        }
      })
    },
    getColor(user_id) {
      var user = this.post.users.find(o => o.id === user_id)
      if (user.pivot.is_read) return '#00b216'
      else return '#bcbcbc'
    },
    async getGeneralUser(type) {
      await this.$store.dispatch('GET_GENERAL_USER', {'employeeType': type})
    },
    async getDailyCleaning(type) {
      await this.$store.dispatch('GET_DAILY_CLEANING_USER', {'employeeType': type})
    },
    toggleAll() {
      let totalLength = this.userByType.length + this.dailyCleaningUser.length
      let selectedLength = this.selectedUsers.length
      if (selectedLength === 0 || selectedLength !== totalLength) {
        if (selectedLength !== totalLength) {
          this.selectedGeneral = []
          this.selectedCleaning = []
        }
        this.userByType.forEach(element => {
          this.selectedGeneral.push(element.id)
        })
        this.dailyCleaningUser.forEach(element => {
          this.selectedCleaning.push(element.id)
        })
      } else if (
        selectedLength === totalLength &&
        this.selectedCategory.length > 1
      ) {
        this.selectedCategory = [1]
      } else {
        this.selectedGeneral = []
        this.selectedCleaning = []
      }
    },

    toggleGeneral() {
      if (this.selectedCategory.includes(1)) {
        this.selectedCategory = [1]
        return
      }
      let totalLength = this.userByType.length
      let selectedLength = this.selectedGeneral.length
      if (selectedLength === 0 || selectedLength !== totalLength) {
        if (selectedLength !== totalLength) {
          this.selectedGeneral = []
        }
        this.userByType.forEach(element => {
          this.selectedGeneral.push(element.id)
        })
      } else {
        this.selectedGeneral = []
      }
    },

    toggleCleaning() {
      if (this.selectedCategory.includes(1)) {
        this.selectedCategory = [1]
        return
      }
      let totalLength = this.dailyCleaningUser.length
      let selectedLength = this.selectedCleaning.length
      if (selectedLength === 0 || selectedLength !== totalLength) {
        if (selectedLength !== totalLength) {
          this.selectedCleaning = []
        }
        this.dailyCleaningUser.forEach(element => {
          this.selectedCleaning.push(element.id)
        })
      } else {
        this.selectedCleaning = []
      }
    },

    save() {
      this.loading = true
      let post = JSON.parse(JSON.stringify(this.post))
      post.users = JSON.stringify(this.selectedUsers)
      let id = this.post.id
      let data = new FormData()
      data.append('_method', 'PUT')
      for (const [key, value] of Object.entries(post)) {
        data.append(key, value ? value : value == 0 ? 0 : '')
      }

      this.$store
        .dispatch('POST_UPDATE', { id, data })
        .then(
          response => {
            if (response.data.status == 'success') {
              this.$router.push({
                path: '/post/list'
                // query: { status: 'success', message: response.data.data.message }
              })
            }
          },
          error => {
            this.$swal.fire({
              title: 'システムエラー',
              text:
                '登録時に不具合が生じています。システム管理者へ問い合わせてください。',
              icon: 'error',
              confirmButtonText: '閉じる'
            })
            throw error
          }
        )
        .finally(() => (this.loading = false))
    },

    deleteItem() {
      this.showModal({
        title: '',
        text: 'このアクションを実施してよろしいですか',
        action: {
          process: {
            color: 'red',
            text: '実施する'
          },
          cancel: {
            color: 'secondary',
            text: 'キャンセル'
          }
        },
        func: this.remove
      })
    },

    remove() {
      this.modal.delete = false
      this.$store.dispatch('POST_REMOVE', this.id).then(
        response => {
          if (response.data.status == 'success') {
            this.$router.push({
              name: 'PostList',
              query: { status: 'success', message: response.data.data.message }
            })
          }
        },
        error => {
          this.$swal.fire({
            title: 'システムエラー',
            text:
              '登録時に不具合が生じています。システム管理者へ問い合わせてください。',
            icon: 'error',
            confirmButtonText: '閉じる'
          })
          throw error
        }
      )
    }
  }
}
</script>

<style src="./Create.scss" lang="scss" scoped></style>
