var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-overlay', {
    attrs: {
      "value": _vm.overlay
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": "64"
    }
  })], 1), _c('div', {
    staticClass: "task-container pa-9 pa-md-6 pa-lg-9"
  }, [_c('v-container', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "mb-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', {
    staticClass: "page-title font-weight-bold"
  }, [_vm._v("お知らせの編集")]), _c('span', [_vm._v("対象の従業員にお知らせを表示します。")])]), _vm.post ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "form-content"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-heading-3 text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("お知らせの日付")]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "nudge-right": 40,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "readonly": "",
            "dense": "",
            "hide-details": "",
            "outlined": "",
            "placeholder": "日付を選択してください"
          },
          model: {
            value: _vm.post.post_date,
            callback: function callback($$v) {
              _vm.$set(_vm.post, "post_date", $$v);
            },
            expression: "post.post_date"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }], null, false, 878030519),
    model: {
      value: _vm.menu,
      callback: function callback($$v) {
        _vm.menu = $$v;
      },
      expression: "menu"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "day-format": _vm.formatDate
    },
    on: {
      "input": function input($event) {
        _vm.menu = false;
      }
    },
    model: {
      value: _vm.post.post_date,
      callback: function callback($$v) {
        _vm.$set(_vm.post, "post_date", $$v);
      },
      expression: "post.post_date"
    }
  })], 1)], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-heading-3 text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("タイトル")]), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "9"
    }
  }, [_c('v-text-field', {
    staticClass: "form-text",
    attrs: {
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "placeholder": "お知らせのタイトルを入力してください。"
    },
    model: {
      value: _vm.post.title,
      callback: function callback($$v) {
        _vm.$set(_vm.post, "title", $$v);
      },
      expression: "post.title"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "form-header text-heading-3 text-right mt-1",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("目標メンバー")]), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "9"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, _vm._l(_vm.participantCategories, function (participantCategory, pCIndex) {
    return _c('v-col', {
      key: "participant-".concat(pCIndex),
      staticClass: "pb-0",
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-checkbox', {
      staticClass: "mt-0",
      attrs: {
        "value": participantCategory.id,
        "label": participantCategory.name
      },
      on: {
        "click": participantCategory.function
      },
      model: {
        value: _vm.selectedCategory,
        callback: function callback($$v) {
          _vm.selectedCategory = $$v;
        },
        expression: "selectedCategory"
      }
    })], 1);
  }), 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "user-type-label text-heading-3 pl-0"
  }, [_vm._v(" 一般社員 ")]), _c('div', {
    staticClass: "user-type-label-divider"
  })]), _vm._l(_vm.userByType, function (user, userIndex) {
    return _c('v-col', {
      key: "general-user-".concat(userIndex),
      staticClass: "py-0",
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-checkbox', {
      staticClass: "my-0",
      staticStyle: {
        "display": "inline-flex"
      },
      attrs: {
        "value": user.id,
        "label": user.full_name
      },
      model: {
        value: _vm.selectedGeneral,
        callback: function callback($$v) {
          _vm.selectedGeneral = $$v;
        },
        expression: "selectedGeneral"
      }
    }), _vm.selectedGeneral.includes(user.id) && _vm.generalUsers.includes(user.id) ? _c('v-icon', {
      staticClass: "mt-n6",
      attrs: {
        "size": "14",
        "color": _vm.getColor(user.id)
      }
    }, [_vm._v("$checkCircleIcon")]) : _vm._e()], 1);
  })], 2), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "user-type-label text-heading-3 pl-0"
  }, [_vm._v(" 日常清掃員 ")]), _c('div', {
    staticClass: "user-type-label-divider"
  })]), _vm._l(_vm.dailyCleaningUser, function (user, userIndex) {
    return _c('v-col', {
      key: "general-user-".concat(userIndex),
      staticClass: "pt-0",
      staticStyle: {
        "display": "inline-flex"
      },
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-checkbox', {
      staticClass: "my-0",
      attrs: {
        "value": user.id,
        "label": user.full_name
      },
      model: {
        value: _vm.selectedCleaning,
        callback: function callback($$v) {
          _vm.selectedCleaning = $$v;
        },
        expression: "selectedCleaning"
      }
    }), _vm.selectedCleaning.includes(user.id) && _vm.cleaningUsers.includes(user.id) ? _c('v-icon', {
      attrs: {
        "size": "14",
        "color": _vm.getColor(user.id)
      }
    }, [_vm._v("$checkCircleIcon")]) : _vm._e()], 1);
  })], 2)], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "form-header text-heading-3 text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("内容")]), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "9"
    }
  }, [_c('v-textarea', {
    staticClass: "form-text",
    attrs: {
      "hide-details": "",
      "outlined": "",
      "placeholder": "お知らせ内容",
      "rows": "16"
    },
    model: {
      value: _vm.post.content,
      callback: function callback($$v) {
        _vm.$set(_vm.post, "content", $$v);
      },
      expression: "post.content"
    }
  })], 1)], 1)], 1)]) : _vm._e()], 1)], 1)], 1), _c('div', {
    staticClass: "create-task-footer px-6"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "rounded": "",
      "width": "150",
      "color": "#AD4545",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.deleteItem();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("$trash")]), _vm._v(" 削除する ")], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "mr-10",
    attrs: {
      "rounded": "",
      "width": "150",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push('/post/list');
      }
    }
  }, [_vm._v(" キャンセル ")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "white--text",
    attrs: {
      "color": "#4F55A7",
      "width": "150",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" 更新する ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }